var mdate=new Date;
var day = mdate.getDate() - 2;
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
var month = monthNames[mdate.getMonth()];
var year = mdate.getFullYear();
document.getElementById("year").innerHTML = document.getElementById("year-1").innerHTML = year;
// document.getElementById("date").innerHTML=day+" "+month+" "+year;
var x = document.getElementById("burger-menu");
x.addEventListener("click", myFunction);
function myFunction() {
  var element = document.getElementById("nav-list");
  element.classList.toggle("active");
  x.classList.toggle("active");
}368760